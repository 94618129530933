import React, { useState, useEffect } from "react";
import styles from "./../system.module.scss";
import Table from 'utils/Table';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp, faFileArrowDown } from '@fortawesome/free-solid-svg-icons'

const head = [[
    { title: "번호", col: "no" },
    { title: "발생일", col: "occurrenceDt" },
    { title: "조치일", col: "actionDt" },
    { title: "상태", col: "status" },
    { title: "설치 ID", col: "installId" },
    { title: "위치 ID", col: "locationId" },
    { title: "행정구역 명", col: "districtNm" },
    { title: "행정구역 코드", col: "districtCd" },
    { title: "Cell ID", col: "cellId" },
    { title: "자산 ID", col: "assetId" },
    { title: "IP주소", col: "ipAddress" },
    { title: "MAC주소", col: "macAddress" },
    { title: "비고", col: "etc" },
],
[

]
]


const ObstacleApp = (props:any) => {

    const [ tableData, setTableData ] = useState<any>(null);

    useEffect(() => {
        const d = new Array(368).fill(null).map((rowData, index) => {
            return { no: index, occurrenceDt: "2023.09.09", actionDt: "2023.09.09", status:"장애", installId:"INS-202030901",locationId :"POS_001"
                , districtNm: "제주시 한림읍" , districtCd: "39-010-110",cellId:"B05"
                ,assetId:"ASS-2023-001", ipAddress:"192.168.123.201", macAddress:"38-FC-98-6F-DD-21",etc: "" }
        })
        setTableData(d);
    },[])

    return (
        <>
            <div className={`${styles.filterContainer}`}>
                <div className={`${styles.filterForm}`}>
                    <table className={`${styles.filter}`}>
                        <tbody>
                            <tr>
                                <td>장애 발생일</td>
                                <td>
                                    <input type="date" id={`${styles.startDate}`}/> ~ <input type="date" id={`${styles.endDate}`}/>
                                </td>
                                <td>행정구역 명</td>
                                <td>
                                    <select id="risk-level" style={{width:"100%"}}>
                                        <option value="">선택</option>
                                        <option value="option1">option1</option>
                                        <option value="option2">option2</option>
                                        <option value="option3">option3</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>상태</td>
                                <td colSpan={3}>
                                    <select id="type">
                                        <option value="">선택</option>
                                        <option value="option1">option1</option>
                                        <option value="option2">option2</option>
                                        <option value="option3">option3</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button className={`${styles.searchBtn}`}>조회</button>
                    
                </div>
                <div className={`${styles.fileControls}`}>
                    <div className={`${styles.fileUpload}`}>
                        <a href="#">
                            <FontAwesomeIcon icon={faFileArrowUp} />
                            파일 업로드
                        </a>
                    </div>
                    <div className={`${styles.fileDownload}`}>
                        <a href="#">
                            <FontAwesomeIcon icon={faFileArrowDown} />
                            파일 다운로드
                        </a>
                    </div>
                </div>
            </div>
            <div className={`${styles.tableGroup}`}>
                <Table head={head} body={tableData} />
                <div className={`${styles.btnGroup}`}>
                    <div className={`${styles.btnCancle}`}>취소</div>
                    <div className={`${styles.btnConfirm}`}>확인</div>
                </div>
            </div>
        </>
    )

};

export default ObstacleApp;
