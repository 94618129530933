import React, { useState, useMemo } from "react";
import styles from "./mypage.module.scss";
import BreadCrumb from "../2.Breadcrumb";
import { Modal } from "utils/Modal/modal";

const Page1 = () => {
    return (
        <>
            <h3>이용자 정보</h3>
            <table>
                <colgroup>
                    <col width="130px"></col>
                    <col />
                </colgroup>
                <tbody>
                    <tr>
                        <td className={`${styles.name}`}>아이디</td>
                        <td>abcded123</td>
                    </tr>
                    <tr>
                        <td className={`${styles.name}`}>이름</td>
                        <td>홍길동</td>
                    </tr>
                    <tr>
                        <td className={`${styles.name}`}>휴대폰</td>
                        <td>010 - 1234 - 5678</td>
                    </tr>
                    <tr>
                        <td className={`${styles.name}`}>이메일</td>
                        <td>hello.world@jeju.go.kr</td>
                    </tr>
                    <tr>
                        <td className={`${styles.name}`}>소속부서</td>
                        <td>도로교통과</td>
                    </tr>
                </tbody>
            </table>
        </>
    );
};

const Page2 = () => {
    const [ModalOpen, SetModalOpen] = useState(false)
    const [headerData, SetHeaderData] = useState<React.ReactNode>(null);
    
    const openModal = () => {
        SetHeaderData(<div style={{display:"flex", gap:"10px", alignItems:"center"}}>
            <p style={{fontWeight:"bold"}}>마이페이지</p><p style={{fontSize:"14px"}}> : 이용자 정보 변경 완료</p>
        </div>);
        SetModalOpen(true);
    };
    
    const closeModal = () => {
        SetModalOpen(false);
    };

    return (
        <>
            <div className={"d-flex"} style={{justifyContent: "space-between", marginBottom: "1rem"}}>
                <h3 style={{margin: 0}}>이용자 정보 변경</h3>
                <span style={{fontSize: "0.8rem", color: "red", alignSelf: "flex-end"}}>• 표시는 필수 입력 항목입니다.</span>
            </div>
            <table>
                <colgroup>
                    <col width="130px"></col>
                    <col />
                </colgroup>
                <tbody>
                    <tr>
                        <td className={`${styles.name}`}>아이디</td>
                        <td>abcded123</td>
                    </tr>
                    <tr>
                        <td className={`${styles.name}`}>이름</td>
                        <td>홍길동</td>
                    </tr>
                    <tr>
                        <td className={`${styles.name}`}>휴대폰</td>
                        <td>
                            <div className={"d-flex"}>
                                <input type="text"></input>
                                <span style={{ alignSelf: "center" }}>-</span>
                                <input type="text"></input>
                                <span style={{ alignSelf: "center" }}>-</span>
                                <input type="text"></input>
                                <div>
                                    <p className={styles.PhoneWarn}>
                                        * 6~12자 영문, 숫자, 특수문자 조합 중 2가지 이상을 사용해주세요
                                    </p>
                                    <p className={styles.PhoneWarn}>* 연속된 문자는 제한됩니다. (4자 이상 제한)</p>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className={`${styles.name}`}><sup style={{ fontSize: "1.2rem", color: "red" }}>•</sup>이메일</td>
                        <td>
                            <div className={"d-flex"}>
                                <input type="text"></input>
                                <span style={{ alignSelf: "center" }}>@</span>
                                <input type="text"></input>
                                <select>
                                    <option>직접입력</option>
                                </select>
                                <div className={`${styles.btnDup}`}>중복 체크</div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className={`${styles.name}`}>소속부서</td>
                        <td>
                            <input type="text"></input>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className={`${styles.btnGroup}`}>
                <div className={`${styles.btnCancle}`}>취소</div>
                <div className={`${styles.btnConfirm}`} onClick={openModal}>확인</div>
            </div>
            <Modal isOpen={ModalOpen} onClose={closeModal} headerData={headerData}>
                <div style={{fontSize:"25px", color:"#3aadff", fontWeight:"bold"}}>이용자 정보가 변경되었습니다.</div>
                <div style={{paddingTop:"30px"}}>선택 정보를 변경하신 경우, <br/>
                관리자 확인 후 변경이 완료됩니다.</div>
            </Modal>
        </>
    );
};

const test = () => {
    
}

const MyPage = (props:any) => {
    const [Page, setPage] = useState<number>(0);

    const renderPage = useMemo(() => {
        if (Page === 0) return <Page1 />;
        else if (Page === 1) return <Page2 />;
        else return null;
    }, [Page]);

    return (
        <main>
            <BreadCrumb breadcrumb={props.breadcrumb} />
            <div className={`common-title-box`}>
                <div>
                    <h2>마이페이지</h2>
                    <p>이용자 정보입니다.</p>
                </div>
            </div>
            {/* <div className={`${styles.MyPageContainer}`}> */}
                <div className={`${styles.MyPageLeft}`}>
                    <div
                        className={`${styles.LeftItem} ${Page === 0 && styles.active}`}
                        onClick={() => setPage(0)}
                        aria-hidden="true"
                    >
                        이용자 정보 조회
                    </div>
                    <div
                        className={`${styles.LeftItem} ${Page === 1 && styles.active}`}
                        onClick={() => setPage(1)}
                        aria-hidden="true"
                    >
                        이용자 정보 관리
                    </div>
                </div>
                <div className={`${styles.MyPageMain}`}>{renderPage}</div>
            {/* </div> */}
        </main>
    );
};

export default MyPage;

