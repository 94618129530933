import React, { useState, useEffect } from "react";
import styles from "./../system.module.scss";
import Table from 'utils/Table';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp, faFileArrowDown } from '@fortawesome/free-solid-svg-icons'

const head = [[
    { title: "번호", col: "no" },
    { title: "접속 날짜", col: "accessDt" },
    { title: "접속시간", col: "accessTime" },
    { title: "사용자", col: "user" },
    { title: "권한", col: "auth" },
    { title: "메뉴명", col: "menuNm" },
    { title: "URL", col: "url" },
    { title: "비고", col: "etc" },
],
[

]
]


const LogApp = (props:any) => {

    const [ tableData, setTableData ] = useState<any>(null);

    useEffect(() => {
        const d = new Array(368).fill(null).map((rowData, index) => {
            return { no: index, accessDt: "2023.09.09", accessTime: "23:59:59", user:"홍길동", auth:"사용자",menuNm :"메인페이지"
                , url: "https://" , etc: "" }
        })
        setTableData(d);
    },[])

    return (
        <>
            <div className={`${styles.filterContainer}`}>
                <div className={`${styles.filterForm}`}>
                    <table className={`${styles.filter}`}>
                        <tbody>
                            <tr>
                                <td>조회 기간</td>
                                <td>
                                    <input type="date" id={`${styles.startDate}`}/> ~ <input type="date" id={`${styles.endDate}`}/>
                                </td>
                                <td>매뉴얼</td>
                                <td>
                                    <select id="risk-level" style={{width:"100%"}}>
                                        <option value="">선택</option>
                                        <option value="option1">option1</option>
                                        <option value="option2">option2</option>
                                        <option value="option3">option3</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>사용자</td>
                                <td colSpan={3}>
                                    <select id="type">
                                        <option value="">선택</option>
                                        <option value="option1">option1</option>
                                        <option value="option2">option2</option>
                                        <option value="option3">option3</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button className={`${styles.searchBtn}`}>조회</button>
                    
                </div>
                <div className={`${styles.fileControls}`}>
                    <div className={`${styles.fileUpload}`}>
                        <a href="#">
                            <FontAwesomeIcon icon={faFileArrowUp} />
                            파일 업로드
                        </a>
                    </div>
                    <div className={`${styles.fileDownload}`}>
                        <a href="#">
                            <FontAwesomeIcon icon={faFileArrowDown} />
                            파일 다운로드
                        </a>
                    </div>
                </div>
            </div>
            <div className={`${styles.tableGroup}`}>
                <Table head={head} body={tableData} />
                <div className={`${styles.btnGroup}`}>
                    <div className={`${styles.btnCancle}`}>취소</div>
                    <div className={`${styles.btnConfirm}`}>확인</div>
                </div>
            </div>
        </>
    )
   
};

export default LogApp;
