import React, { useEffect, useRef, useState } from "react";
import logo from "./logo.svg";

import axios from "utils/axios";

const { kakao } = window;

const MAP_OPTIONS = {
  center: new kakao.maps.LatLng(33.341695, 126.562487),
  level: 8,
};

function App() {
  const { kakao } = window;
  const mapRef = useRef<any>(null);
  const kakaomap = useRef<any>(null);
  
  const [mapView, setMapView] = useState<boolean>(false);

  useEffect(() => {
    setMapView(true);
    return () => {
      kakaomap.current = null;
      setMapView(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mapView) {
      kakaomap.current = new kakao.maps.Map(mapRef.current, MAP_OPTIONS);
      kakaomap.current.customOverlay = new kakao.maps.CustomOverlay({});
      kakaomap.current.infowindow = new kakao.maps.InfoWindow({removable: true});

      const zoomControl = new kakao.maps.ZoomControl();
      kakaomap.current.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);
      kakaomap.current.setMinLevel(1);
      kakaomap.current.setMaxLevel(10);
    }
    return () => {kakaomap.current = null}
  }, [mapView]);


  let markers1: any =  [];
  const getPolygon1 = async () => {
    try {
      const request = await axios.post("/map/getPolygon");
      const { data } = request?.data;
      let dataList: any[] =  []; 
      for (const i of data) {
        const s1 = i.s1.split(" ");
        const s2 = i.s2.split(" ");
        const s3 = i.s3.split(" ");
        const s4 = i.s4.split(" ");
        const s5 = i.s5.split(" ");

        const rectanglePath = [
          new kakao.maps.LatLng(s1[1],s1[0]),
          new kakao.maps.LatLng(s2[1],s2[0]),
          new kakao.maps.LatLng(s3[1],s3[0]),
          new kakao.maps.LatLng(s4[1],s4[0]),
          new kakao.maps.LatLng(s5[1],s5[0]),
        ];
        dataList.push({name : i.H_Number , path: rectanglePath});
      }
      for (var i = 0; i < dataList.length; i ++) {
        // 마커를 생성합니다
        let  polygon: any = new kakao.maps.Polygon({
          map : kakaomap.current, 
          path: dataList[i].path,
          strokeWeight: 0.8,
          strokeColor: "#b5a39f",
          strokeOpacity: 1,
          strokeStyle: "solid",
           fillColor: "#d8b3a9",
          fillOpacity: 0.2,
        });
        markers1.push(polygon);
      }
    } catch (e) {
      console.error(e);
    }      
  };

  let markers2: any =  [];
  const getPolygon2 = async () => {
    try {
      const request = await axios.post("/map/getPolygon2");
      const { data } = request?.data;
      let dataList: any[] =  []; 
      for (const i of data) {
        const s1 = i.s1.split(" ");
        const s2 = i.s2.split(" ");
        const s3 = i.s3.split(" ");
        const s4 = i.s4.split(" ");
        const s5 = i.s5.split(" ");

        const rectanglePath = [
          new kakao.maps.LatLng(s1[1],s1[0]),
          new kakao.maps.LatLng(s2[1],s2[0]),
          new kakao.maps.LatLng(s3[1],s3[0]),
          new kakao.maps.LatLng(s4[1],s4[0]),
          new kakao.maps.LatLng(s5[1],s5[0]),
        ];
        dataList.push({'name': i.H_Number+"" , 'mode': i.mode, 'path': rectanglePath});
      }

      for (var i = 0; i < dataList.length; i ++) {
        let mdoeColor: String ="";
        if( dataList[i].mode  === "1") mdoeColor = "#fa4704";
        if( dataList[i].mode  === "2") mdoeColor = "#f4fc0a";
        if( dataList[i].mode  === "3") mdoeColor = "#50f92f";
        
        let disName: string = dataList[i].name; 
        // 마커를 생성합니다
        let polygon: any = new kakao.maps.Polygon({
          map : kakaomap.current, 
          path: dataList[i].path,
          strokeWeight: 0.8,
          strokeColor: "#004c80",
          strokeOpacity: 1,
          strokeStyle: "solid",
           fillColor: mdoeColor,
          fillOpacity: 1
        });

        kakao.maps.event.addListener(polygon, 'mouseover', function(mouseEvent:any) {
          polygon.setOptions({fillColor: '#09f', fillOpacity: "0.7" });

          kakaomap.current.customOverlay.setContent('<div class="area">' + disName + '</div>');
          
          kakaomap.current.customOverlay.setPosition(mouseEvent.latLng); 
          kakaomap.current.customOverlay.setMap(kakaomap.current);
        });


        kakao.maps.event.addListener(polygon, 'mousemove', function(mouseEvent:any) {
        
          kakaomap.current.customOverlay.setPosition(mouseEvent.latLng); 
      });
  
      // 다각형에 mouseout 이벤트를 등록하고 이벤트가 발생하면 폴리곤의 채움색을 원래색으로 변경합니다
      // 커스텀 오버레이를 지도에서 제거합니다 
      kakao.maps.event.addListener(polygon, 'mouseout', function() {
          polygon.setOptions({fillColor: '#fff',fillOpacity: "0.2"});
          kakaomap.current.customOverlay.setMap(null);
      }); 

        markers2.push(polygon);
      }
      
    } catch (e) {
      console.error(e);
    }
  };


  let markers3: any =  [];
  const getPolygon3 = async () => {
    try {
      const request = await axios.post("/map/getPolygon3");
      const { data } = request?.data;
      let dataList: any[] =  []; 
      for (const i of data) {

        let wkt: String[] = i.WKT.split(', ');
         
        let pathInfo: any[] = [];
        for (let j = 0; j < wkt.length; j++) {
          const s1: String[] = wkt[j].split(" ");
          pathInfo.push(new kakao.maps.LatLng(s1[1],s1[0]));
        }

        dataList.push({'name': i.emd_code+"" , 'path': pathInfo});
      }
      for (var i = 0; i < dataList.length; i ++) {
        
        let disName: string = dataList[i].name; 
        // 마커를 생성합니다
        let polygon: any = new kakao.maps.Polygon({
          map : kakaomap.current, 
          path: dataList[i].path,
          strokeWeight: 0.8,
          strokeColor: "#004c80",
          strokeOpacity: 1,
          strokeStyle: "solid",
           fillColor: "#fff",
          fillOpacity: 0.2
        });

        kakao.maps.event.addListener(polygon, 'mouseover', function(mouseEvent:any) {
          polygon.setOptions({fillColor: '#09f', fillOpacity: "0.7" });

          kakaomap.current.customOverlay.setContent('<div class="area">' + disName + '</div>');
          
          kakaomap.current.customOverlay.setPosition(mouseEvent.latLng); 
          kakaomap.current.customOverlay.setMap(kakaomap.current);
        });


        kakao.maps.event.addListener(polygon, 'mousemove', function(mouseEvent:any) {
        
          kakaomap.current.customOverlay.setPosition(mouseEvent.latLng); 
      });
  
      // 다각형에 mouseout 이벤트를 등록하고 이벤트가 발생하면 폴리곤의 채움색을 원래색으로 변경합니다
      // 커스텀 오버레이를 지도에서 제거합니다 
      kakao.maps.event.addListener(polygon, 'mouseout', function() {
          polygon.setOptions({fillColor: '#fff',fillOpacity: "0.2"});
          kakaomap.current.customOverlay.setMap(null);
      }); 

        markers3.push(polygon);
      }
      
    } catch (e) {
      console.error(e);
    }
  };
    

  const setMap = () =>{

    for(var idx=0; idx< markers1.length; idx++){
      markers1[idx].setMap(null);
    }

    for(var idx=0; idx< markers2.length; idx++){
        markers2[idx].setMap(null);
    }

    for(var idx=0; idx< markers3.length; idx++){
      markers3[idx].setMap(null);
  }

  };

  

  return (
    <div className="App">
  
      <button style={{ position: "absolute", top: "60px", zIndex: 20, left: "50px" }} onClick={getPolygon1}>
        뻐튼(L)
      </button>
      <button style={{ position: "absolute", top: "60px", zIndex: 20, left: "200px" }} onClick={getPolygon2}>
        뻐튼2
      </button>
      <button style={{ position: "absolute", top: "60px", zIndex: 20, left: "300px" }} onClick={getPolygon3}>
        뻐튼3
      </button>
      <button style={{ position: "absolute", top: "60px", zIndex: 20, left: "400px" }} onClick={setMap}>
        지우기
      </button>

      {mapView && (
        <div >
          <div ref={mapRef} id="map" style={{ width: "1800px", height: "800px" }}></div>
        </div>
      )}
    </div>
  );
}

export default App;
