// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_LoginContainer__WV7e0 {
  width: 100%;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login_LoginContainer__WV7e0 input[type=text],
.login_LoginContainer__WV7e0 input[type=password] {
  width: 100%;
}

.login_LoginContainer__WV7e0 input[type=text]::placeholder,
.login_LoginContainer__WV7e0 input[type=password]::placeholder {
  font-size: 0.8rem;
}

.login_LoginContainer__WV7e0 button {
  background-color: var(--btn-color1);
  border: none;
  width: 100%;
  height: 60px;
  color: #FFF;
  cursor: pointer;
}

.login_LoginContainer__WV7e0 label {
  font-size: 0.8rem;
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/10.Login/login.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEA;;EAEI,WAAA;AACJ;;AACA;;EAEI,iBAAA;AAEJ;;AAAA;EACI,mCAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;AAGJ;;AADA;EACI,iBAAA;EACA,aAAA;EACA,mBAAA;AAIJ","sourcesContent":[".LoginContainer {\n    width: 100%;\n    margin-top: 2rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.LoginContainer input[type=text],\n.LoginContainer input[type=password] {\n    width: 100%;\n}\n.LoginContainer input[type=text]::placeholder,\n.LoginContainer input[type=password]::placeholder {\n    font-size: 0.8rem;\n}\n.LoginContainer button {\n    background-color: var(--btn-color1);\n    border: none;\n    width: 100%;\n    height: 60px;\n    color: #FFF;\n    cursor: pointer;\n}\n.LoginContainer label {\n    font-size: 0.8rem;\n    display: flex;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LoginContainer": `login_LoginContainer__WV7e0`
};
export default ___CSS_LOADER_EXPORT___;
