import React from "react";
import { Route, Routes, Outlet, BrowserRouter, useLocation } from "react-router-dom";
import BreadCrumb from "../2.Breadcrumb";
import SystemLeftMenu from "./leftMenu";
import styles from "./system.module.scss";

import UserApp from "./10.user";
import AuthorityApp from "./11.authority";
import CodeApp from "./12.code";
import LocationApp from "./13.location";
import AssetApp from "./14.asset";
import InstallationApp from "./15.installation";
import ObstacleApp from "./16.obstacle";
import LogApp from "./17.logMng";
import RemoteApp from "./18.remote";


const contentInfo = [{path:"user", title:"사용자 관리", desc:"시스템 사용자를 관리할 수 있습니다." }
    , {path:"authority", title:"권한 관리", desc:"시스템 사용자를 관리할 수 있습니다. " }
    , {path:"code", title:"코드 관리", desc:"시스템에서 사용하는 각종 코드를 관리할 수 있습니다." }
    , {path:"location", title:"위치 관리", desc:"안전지원 장비가 설치될 위치를 관리할 수 있습니다." }
    , {path:"asset", title:"자산 관리", desc:"자산(안전지원 장비)을 관리할 수 있습니다." }
    , {path:"install", title:"설치 관리", desc:"안전지원 장비의 설치/철거 등을 관리할 수 있습니다." }
    , {path:"obstacle", title:"장애 관리", desc:"지역에 설치된 안전지원 장비의 장애 관리를 할 수있습니다." }
    , {path:"log", title:"로그 관리", desc:"" }
    , {path:"remote", title:"장비 원격지원", desc:"다음과 같은 절차를 통해서 안전지원 장비를 원격에서 제어할 수 있습니다." }]



const SystemLayout = (breadcrumb:any) => {

    const location = useLocation();
    let path = window.location.pathname.replace("/system-management/", "") === "/system-management" ? "user" : window.location.pathname.replace("/system-management/", "")  ; 

    const pageInfo = contentInfo.filter((f:any) =>  f.path === path  )[0]
    

    return <div>
        <BreadCrumb breadcrumb={breadcrumb.breadcrumb} />
        <div className={`common-title-box`}>
                <div>
                    <h2>{pageInfo.title}</h2>
                    <p>{pageInfo.desc}</p>
                </div>
        </div>
        <div className={`${styles.contentBox}`}>
            <SystemLeftMenu />
            <div className={`${styles.content}`}>
                <Outlet />
            </div>
        </div>
    </div>
};

const SafeManagement = (props:any) => {

    return (
        <>
        <main>
        <Routes>
            <Route path="/*" element={<SystemLayout breadcrumb={[{ name: "Home" }, { name: "시스템 관리" }]} />}>
                <Route index  element={<UserApp />} />
                <Route path="authority" element={<AuthorityApp />} />
                <Route path="code" element={<CodeApp />} />
                <Route path="location" element={<LocationApp />} />
                <Route path="asset" element={<AssetApp />} />
                <Route path="install" element={<InstallationApp />} />
                <Route path="obstacle" element={<ObstacleApp />} />
                <Route path="log" element={<LogApp />} />
                <Route path="remote" element={<RemoteApp />} />
            </Route>
        </Routes>

        </main>
        </>        
    );
};





export default SafeManagement;

