import React, { useEffect, useRef, useState, useContext, useCallback } from "react";
import styles from "./main.module.scss";
import axios from "utils/axios";
import { InitialGrid, getFloodData } from "./functions";
import { MainContext } from ".";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWater, faHouseFloodWater, faTv, faLocationDot, faClock, faCirclePlay, faPause, faStop } from '@fortawesome/free-solid-svg-icons'
import { ObstacleModal, GraphModal, TestModal } from "utils/Modal/modal";

import TimeLines from "./Timeline";

const { kakao } = window;

let tm_out: any;


const MAP_OPTIONS = {
    center: new kakao.maps.LatLng(33.371695, 126.562487),
    level: 8,
};

const Map = (props: any) => {
    const mapRef = useRef<any>(null);
    const kakaomap = useRef<any>(null);
    const { infoData } = useContext(MainContext);
    const { FloodDataList } = useContext(MainContext);
    const { GetFloodData } = useContext(MainContext);
    const { workClass } = useContext(MainContext);

    const [ModalOpen, SetModalOpen] = useState(false)
    const [ModalData, SetModalData] = useState<any[]>([]);

    const [GraphModalOpen, SetGraphModalOpen] = useState(false)
    const [GraphModalData, SetGraphModalData] = useState<any[]>([]);

    const [mapView, setMapView] = useState<boolean>(false);
    
    const Markers1 = useRef<any[]>([]);  //침수
    const Markers2 = useRef<any[]>([]);  //홍수
    const Markers3 = useRef<any[]>([]);  //영역
    const Markers4 = useRef<any[]>([]);  //마커
    const Markers5 = useRef<any[]>([]);  //rotation 마커

    const [ShowMark1, SetShowMark1] = useState<boolean>(false);
    const [ShowMark2, SetShowMark2] = useState<boolean>(false);
    const [ShowMark3, SetShowMark3] = useState<boolean>(false);
    const [ShowMark4, SetShowMark4] = useState<boolean>(false);
    const [ShowPlayItems, SetShowPlayItems] = useState<boolean>(false);
    const [ShowClockBtn, SetShowClockBtn] = useState<boolean>(false);

    const [InitialTime, setInitialTime] = useState<number>(0);
    const [CurrentTime, setCurrentTime] = useState<number>(0);
    const [SavedTime, setSavedTime] = useState<number>(0);
    const [IsRunning, setIsRunning] = useState<boolean>(false);
    const Interval = useRef<NodeJS.Timeout | null>(null);
    const [RotationData, SetRotationData] = useState<any[]>([]);

    const formatTimeDelta = (timedelta: number): string => {
        const [ss] = [
            (parseInt((timedelta / 1000).toString()) % 53) 
        ].map((x) =>
            x.toLocaleString("ko-KR", {
                minimumIntegerDigits: 2,
                useGrouping: false,
            })
        );
        return `53 / ${ss}`;
    };

    useEffect(() => {

        const time = new Date().getTime();
        setCurrentTime(time);
        setInitialTime(time);

        setMapView(true);
        return () => {
        kakaomap.current = null;
        setMapView(false);
        };
    }, []);
    
    useEffect(() => {

        if(workClass === '1') {
            SetShowMark1(false);
            SetShowMark2(true);
        } else if(workClass === '2') {
            SetShowMark2(false);
            SetShowMark1(true);
        }

        if(mapView && kakaomap.current != null){
            kakaomap.current.setCenter(MAP_OPTIONS.center);
            kakaomap.current.setLevel(MAP_OPTIONS.level);
        }
    }, [workClass]);

    

    useEffect(() => {

        if(GetFloodData !== null) {
            const xPoint:string[] = GetFloodData.s1.split(' ');
            var moveLatLon = new kakao.maps.LatLng(xPoint[1], xPoint[0]);
            if(mapView && kakaomap.current != null){
                kakaomap.current.setCenter(moveLatLon);
                kakaomap.current.setLevel(2);
            }
        } 
        
    }, [GetFloodData]);


    useEffect(() => {
        if (mapView) {
            kakaomap.current = new kakao.maps.Map(mapRef.current, MAP_OPTIONS);
            kakaomap.current.customOverlay = new kakao.maps.CustomOverlay({});
            kakaomap.current.infowindow = new kakao.maps.InfoWindow({removable: true});

            const zoomControl = new kakao.maps.ZoomControl();
            kakaomap.current.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);
            kakaomap.current.setMinLevel(1);
            kakaomap.current.setMaxLevel(10);
        }
        return () => {kakaomap.current = null}
    }, [mapView]);

    const getPolygon1 = async () => {
        try {
            if(FloodDataList === null) return;
            const rowData:any = FloodDataList.filter((data:any) =>  data.workClass === '1');
            for (const i of rowData) {

                let color:String = "#3aadff";
                if(i.dangerLevel === '1'){
                    color = '#ffff00';
                } else if(i.dangerLevel === '2'){
                    color = '#ff7f00';
                } else if(i.dangerLevel === '3'){
                    color = '#ff0000';
                }

                const s1 = i.s1.split(" ");
                const s2 = i.s2.split(" ");
                const s3 = i.s3.split(" ");
                const s4 = i.s4.split(" ");
                const s5 = i.s5.split(" ");

                const rectanglePath = [
                    new kakao.maps.LatLng(s1[1],s1[0]),
                    new kakao.maps.LatLng(s2[1],s2[0]),
                    new kakao.maps.LatLng(s3[1],s3[0]),
                    new kakao.maps.LatLng(s4[1],s4[0]),
                    new kakao.maps.LatLng(s5[1],s5[0]),
                ];

                const polygon = new kakao.maps.Polygon({
                    map : kakaomap.current, 
                    path:rectanglePath, // 그려질 다각형의 좌표 배열입니다
                    strokeWeight: 1, // 선의 두께입니다
                    strokeColor: '#2d30b1', // 선의 색깔입니다
                    strokeOpacity: 0.8, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
                    strokeStyle: 'solid', // 선의 스타일입니다
                    fillColor: color, // 채우기 색깔입니다
                    fillOpacity: 0.4 // 채우기 불투명도 입니다
                });

                kakao.maps.event.addListener(polygon, 'mouseover', function(mouseEvent:any) {
                    polygon.setOptions({fillColor: color, fillOpacity: "0.9" });
        
                    kakaomap.current.customOverlay.setContent('<div class="area">' + i.no + " / " +  i.value + '</div>');
                    
                    kakaomap.current.customOverlay.setPosition(mouseEvent.latLng); 
                    kakaomap.current.customOverlay.setMap(kakaomap.current);
                });
        
        
                kakao.maps.event.addListener(polygon, 'mousemove', function(mouseEvent:any) {
                    kakaomap.current.customOverlay.setPosition(mouseEvent.latLng); 
                });
            
                // 다각형에 mouseout 이벤트를 등록하고 이벤트가 발생하면 폴리곤의 채움색을 원래색으로 변경합니다
                // 커스텀 오버레이를 지도에서 제거합니다 
                kakao.maps.event.addListener(polygon, 'mouseout', function() {
                    polygon.setOptions({fillColor: color ,fillOpacity: "0.4"});
                    kakaomap.current.customOverlay.setMap(null);
                }); 

                Markers1.current.push(polygon);
            }
        } catch (e) {
            console.error(e);
        }      
    };

    const getPolygon2 = async () => {
        try {
            if(FloodDataList === null) return;
            const rowData:any = FloodDataList.filter((data:any) => data.workClass === '2');

            for (const i of rowData) {
                if( i.value != '') {

                    let color:String = "#3aadff";
                    if(i.dangerLevel === '1'){
                        color = '#ffff00';
                    } else if(i.dangerLevel === '2'){
                        color = '#ff7f00';
                    } else if(i.dangerLevel === '3'){
                        color = '#ff0000';
                    }
                    const s1 = i.s1.split(" ");
                    const s2 = i.s2.split(" ");
                    const s3 = i.s3.split(" ");
                    const s4 = i.s4.split(" ");
                    const s5 = i.s5.split(" ");
        
                    const rectanglePath = [
                        new kakao.maps.LatLng(s1[1],s1[0]),
                        new kakao.maps.LatLng(s2[1],s2[0]),
                        new kakao.maps.LatLng(s3[1],s3[0]),
                        new kakao.maps.LatLng(s4[1],s4[0]),
                        new kakao.maps.LatLng(s5[1],s5[0]),
                    ];

                    const polygon = new kakao.maps.Polygon({
                        map : kakaomap.current, 
                        path:rectanglePath, // 그려질 다각형의 좌표 배열입니다
                        strokeWeight: 2, // 선의 두께입니다
                        strokeColor: '#c51d42', // 선의 색깔입니다
                        strokeOpacity: 0.8, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
                        strokeStyle: 'solid', // 선의 스타일입니다
                        fillColor: color, // 채우기 색깔입니다
                        fillOpacity: 0.4 // 채우기 불투명도 입니다
                    });

                    kakao.maps.event.addListener(polygon, 'mouseover', function(mouseEvent:any) {
                        polygon.setOptions({fillColor: color, fillOpacity: "0.9" });
            
                        kakaomap.current.customOverlay.setContent('<div class="area">' + i.no + " / " +  i.value + '</div>');
                        
                        kakaomap.current.customOverlay.setPosition(mouseEvent.latLng); 
                        kakaomap.current.customOverlay.setMap(kakaomap.current);
                    });
            
            
                    kakao.maps.event.addListener(polygon, 'mousemove', function(mouseEvent:any) {
                        kakaomap.current.customOverlay.setPosition(mouseEvent.latLng); 
                    });
                
                    // 다각형에 mouseout 이벤트를 등록하고 이벤트가 발생하면 폴리곤의 채움색을 원래색으로 변경합니다
                    // 커스텀 오버레이를 지도에서 제거합니다 
                    kakao.maps.event.addListener(polygon, 'mouseout', function() {
                        polygon.setOptions({fillColor: color ,fillOpacity: "0.4"});
                        kakaomap.current.customOverlay.setMap(null);
                    }); 
    

                    kakao.maps.event.addListener(polygon, 'click', function(mouseEvent:any) {

                        SetGraphModalData([{'disName' : i.no, 'mdoeColor' : color, 'chartData': i.chartData }])
                        SetGraphModalOpen(true);
                    });
                    Markers2.current.push(polygon);                
                }
            }   
        } catch (e) {
            console.error(e);
        }
    };


    const getArea= () =>{

        const boxArr:any = [
            new kakao.maps.LatLng(33.35936818994372999, 126.45525824229238765),
            new kakao.maps.LatLng(33.35936818994372999, 126.57918070720391768),
            new kakao.maps.LatLng(33.53260918002880686, 126.57918070720391768),
            new kakao.maps.LatLng(33.53260918002880686, 126.45525824229238765)
        ];
        let boxPolygon: any  = new kakao.maps.Polygon({
            map : kakaomap.current, 
            path:boxArr, // 그려질 다각형의 좌표 배열입니다
            strokeWeight: 1, // 선의 두께입니다
            strokeColor: '#000', // 선의 색깔입니다
            strokeOpacity: 0.8, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
            strokeStyle: 'solid', // 선의 스타일입니다
        });
        Markers3.current.push(boxPolygon);
    }


        
    const getMarkersData = async () => {
        try {
            let imageSrc = "https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/marker_red.png", // 마커이미지의 주소입니다
            imageSize = new kakao.maps.Size(40, 40), // 마커이미지의 크기입니다
            imageOption = { offset: new kakao.maps.Point(15, 50) }, // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.
            markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption);

            const request = await axios.post("/map/getMarkers");
            const { data } = request.data;

            for (const i of data) {
                const latlng = new kakao.maps.LatLng(i.lat, i.lon);
                let marker: any;
                if (i.status > 0) {
                    marker = new kakao.maps.Marker({ position: latlng });
                } else {
                    marker = new kakao.maps.Marker({ position: latlng, image: markerImage });
                }
                marker.setClickable(true);
                marker.setMap(kakaomap.current);

                kakao.maps.event.addListener(marker, 'click', function(mouseEvent:any) {

                    SetModalData([i])
                    SetModalOpen(true);
                });
                Markers4.current.push(marker);
            }
        
        } catch (e) {
            console.error(e);
        }
    };

    const closeModal = () => {
        SetModalOpen(false);
    };

    const closeGraphModal = () => {
        SetGraphModalOpen(false);
    };

    useEffect(() => {
        
        for(var idx=0; idx< Markers2.current.length; idx++){
            Markers2.current[idx].setMap(null);
        }

        if (ShowMark1) {
            getPolygon2();
            SetShowClockBtn(true);
        } else {
            SetShowClockBtn(false);
        }

    }, [ShowMark1]);    

    useEffect(() => {
        for(var idx=0; idx< Markers1.current.length; idx++){
            Markers1.current[idx].setMap(null);
        }
        if (ShowMark2) {
            getPolygon1();
        }
         
    }, [ShowMark2]);    

    useEffect(() => {
        for(var idx=0; idx< Markers3.current.length; idx++){
            Markers3.current[idx].setMap(null);
        }

        if (ShowMark3) {
            getArea();
        }
    }, [ShowMark3]);    

    useEffect(() => {
        for(var idx=0; idx< Markers4.current.length; idx++){
            Markers4.current[idx].setMap(null);
        }
        if (ShowMark4) {
            getMarkersData()
        }
    }, [ShowMark4]);

    useEffect(() => {
        
        if (IsRunning) {

            let i:number = 1;
            const time = new Date().getTime();
            setInitialTime(time);
            setCurrentTime(time);
            Interval.current = setInterval(() => {
                setCurrentTime(new Date().getTime());
                if(i === 54)  i = 1;
                showRotation(i++) ;

            }, 1000);
        } else {
            if (Interval.current) {
                clearInterval(Interval.current);
            }
            setSavedTime((t) => t + CurrentTime - InitialTime);
            const time = new Date().getTime();
            setCurrentTime(time);
            setInitialTime(time);
            stopRotation();
        }
    }, [IsRunning]);


    const stopRotation = () => {

        for(var idx=0; idx< Markers5.current.length; idx++){
            Markers5.current[idx].setMap(null);
        }
        getPolygon2();
    };

    const showRotation = (i:number) => {

        for(var idx=0; idx< Markers5.current.length; idx++){
            Markers5.current[idx].setMap(null);
        }
        try {

            for (const data of RotationData) {

                const showValue:number = data[i+"tm"];
                if( showValue !==  null) {

                    let color:string = "#3aadff";
                    if(showValue >= 10 && showValue < 25) {
                        color = '#ffff00';
                    } else if (showValue >= 25 &&  showValue < 30) {
                        color = '#ff7f00';
                    } else if (showValue >= 30) {
                        color = '#ff0000';
                    }

                    const s1 = data.s1.split(" ");
                    const s2 = data.s2.split(" ");
                    const s3 = data.s3.split(" ");
                    const s4 = data.s4.split(" ");
                    const s5 = data.s5.split(" ");
        
                    const rectanglePath = [
                        new kakao.maps.LatLng(s1[1],s1[0]),
                        new kakao.maps.LatLng(s2[1],s2[0]),
                        new kakao.maps.LatLng(s3[1],s3[0]),
                        new kakao.maps.LatLng(s4[1],s4[0]),
                        new kakao.maps.LatLng(s5[1],s5[0]),
                    ];

                    const polygon = new kakao.maps.Polygon({
                        map : kakaomap.current, 
                        path:rectanglePath, // 그려질 다각형의 좌표 배열입니다
                        strokeWeight: 1, // 선의 두께입니다
                        strokeColor: '#151312', // 선의 색깔입니다
                        strokeOpacity: 0.8, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
                        strokeStyle: 'solid', // 선의 스타일입니다
                        fillColor: color, // 채우기 색깔입니다
                        fillOpacity: 1 // 채우기 불투명도 입니다
                    });
    
                    Markers5.current.push(polygon);                
                }
            }   
        } catch (e) {
            console.error(e);
        }

    }

    const onRun = async () => {
        try {
            const request = await axios.post("/map/getPolygonRotation");
            const { data } = request.data;
            SetRotationData(data);

            for(var idx=0; idx< Markers2.current.length; idx++){
                Markers2.current[idx].setMap(null);
            }
        } catch (e) {
            console.error(e);
        }            
        setIsRunning(true);
    };

    // const onPause = () => {
    //     setIsRunning(false);
    // }

    const onStop = () => {
        const time = new Date().getTime();
        setIsRunning(false);
        setCurrentTime(time);
        setInitialTime(time);
        setSavedTime(0);
    };


    const [ TestModalOpen, setTestModalOpen ] = useState<boolean>(true)

    return (
        <>
            <div style={{ position: "relative" }}>
                <div className={`${styles.MapTop}`} aria-hidden="true">
                    <span className={`${styles.TopItem}`}><b>홍수/침수 지역: 총 {FloodDataList && FloodDataList.filter((data:any) =>  Number(data.dangerLevel) >= 2).length}
                        ({FloodDataList && (
                        Math.round(FloodDataList.filter((data:any) =>  Number(data.dangerLevel) >= 2).length/FloodDataList.length*100))
                        }%)
                        </b></span>
                    <span className={`${styles.TopItem}`}><b>장애 장비: 총 {infoData?.warnInfo?.marker}({infoData?.warnInfo?.marker_p}%)</b></span>

                </div>

                <div ref={mapRef} id="map" className={`${styles.Map}`}></div>
                <div style={{display:(ShowPlayItems ? "inline" : "none")}}>

                    <div className={`${styles.Timer}`}>{formatTimeDelta(CurrentTime - InitialTime + SavedTime)}</div>

                    <div className={`${styles.Playitems}`}>
                        <div onClick={onRun} style={{color:(IsRunning === true ? "#3aadff" : "#aaa")}}>
                            <FontAwesomeIcon icon={faCirclePlay}/>
                        </div>
                        {/* <div onClick={onPause} style={{color:(IsRunning === false && SavedTime > 0 ? "#3aadff" : "#aaa")}}>
                            <FontAwesomeIcon icon={faPause}/>
                        </div> */}
                        <div onClick={onStop}>
                            <FontAwesomeIcon icon={faStop}/>
                        </div>
                    </div>
                </div>
                <div className={`${styles.ClockBtn}`} style={{display:(ShowClockBtn ? "inline" : "none")}} onClick={() => SetShowPlayItems((show) => !show)}>
                    <FontAwesomeIcon icon={faClock}/>
                </div>
                <div className={`${styles.MapBtnBoxes}`}>
                    <div className={`${styles.quick}`}>Quick</div>  
                    <div className={`${styles.icons}`}>
                        <FontAwesomeIcon icon={faWater} style={{color:(ShowMark1 ? "#3aadff" : "#aaaaaa")}} onClick={() => SetShowMark1((show) => !show)}/>
                    </div>  
                    <div className={`${styles.icons}`}>
                        <FontAwesomeIcon icon={faHouseFloodWater} style={{color:(ShowMark2 ? "#3aadff" : "#aaaaaa")}} onClick={() => SetShowMark2((show) => !show)}/>
                    </div>  
                    <div className={`${styles.icons}`}>
                        <FontAwesomeIcon icon={faTv} style={{color:(ShowMark3 ? "#3aadff" : "#aaaaaa")}} onClick={() => SetShowMark3((show) => !show)} />
                    </div>  
                    <div className={`${styles.icons}`}>
                        <FontAwesomeIcon icon={faLocationDot} style={{color:(ShowMark4 ? "#3aadff" : "#aaaaaa")}} onClick={() => SetShowMark4((show) => !show)}/>
                    </div>
                    <div>
                        <button onClick={() => setTestModalOpen(!TestModalOpen)}>Test</button>
                    </div>
                </div>
            </div>  
            <ObstacleModal isOpen={ModalOpen} onClose={closeModal} data={ModalData}/>
            <GraphModal isOpen={GraphModalOpen} onClose={closeGraphModal} data={GraphModalData}/>
            <TestModal isOpen={TestModalOpen} onClose={() => setTestModalOpen(false)} />
        </>
    );
};

export default Map;

