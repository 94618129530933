import React, { useState, useEffect } from "react";
import styles from "./../system.module.scss";
import Table from 'utils/Table';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp, faFileArrowDown } from '@fortawesome/free-solid-svg-icons'

const head = [[
    { title: "번호", col: "no" },
    { title: "위치ID", col: "locationId" },
    { title: "행정구역명", col: "districtNm" },
    { title: "행정구역코드", col: "districtCd" },
    { title: "Cell ID", col: "cellId" },
    { title: "위도", col: "latitude" },
    { title: "경도", col: "longitude" },
    { title: "위치정보", col: "locationInfo" },
    { title: "등록일", col: "createDt" },
    { title: "변경일", col: "udpateDt" },
    { title: "현황", col: "status" },
    { title: "비고", col: "etc" },
],
[

]
]

const LocationApp = (props:any) => {

    const [ tableData, setTableData ] = useState<any>(null);

    useEffect(() => {
	/*
        const d = new Array(3).fill(null).map((rowData, index) => {
            return { no: index, locationId: "POS_001", districtNm: "제주시 한림읍", districtCd: "39-010-110"
                , cellId: "B05", latitude: "33.4996213", longitude: "126.5311884", locationInfo: "수풀이 우거졌음"
                , createDt: "2023-12-01" , updateDt: "", status: "사용", etc: "" }
        })
	*/
	const d = [
		{ no: 1, locationId: "POS_001", districtNm: "제주시", districtCd: "39-010-110" 
                , cellId: "-", latitude: "33.514152", longitude: "126.527984", locationInfo: "N/A"
                , createDt: "2024-08-01" , updateDt: "", status: "사용", etc: "" }	
		,{ no: 2, locationId: "POS_002", districtNm: "제주시", districtCd: "39-010-111" 
                , cellId: "-", latitude: "33.511421", longitude: "126.526369", locationInfo: "N/A"
                , createDt: "2024-08-01" , updateDt: "", status: "사용", etc: "" }	
		,{ no: 3, locationId: "POS_003", districtNm: "제주시", districtCd: "39-010-112" 
                , cellId: "-", latitude: "33.509067", longitude: "126.513188", locationInfo: "N/A"
                , createDt: "2024-08-01" , updateDt: "", status: "사용", etc: "" }	
		,{ no: 4, locationId: "POS_004", districtNm: "제주시", districtCd: "39-010-113" 
                , cellId: "-", latitude: "33.509067", longitude: "126.511153", locationInfo: "N/A"
                , createDt: "2024-08-01" , updateDt: "", status: "사용", etc: "" }	
		]
        setTableData(d);
    },[])

    return (
        <>
            <div className={`${styles.filterContainer}`}>
                <div className={`${styles.filterForm}`}>
                    <table className={`${styles.filter}`}>
                        <tbody>
                            <tr>
                                <td>등록일</td>
                                <td>
                                    <input type="date" id={`${styles.startDate}`}/> ~ <input type="date" id={`${styles.endDate}`}/>
                                </td>
                                <td>행정구역 명</td>
                                <td>
                                    <select id="risk-level" style={{width:"100%"}}>
                                        <option value="">선택</option>
                                        <option value="option1">option1</option>
                                        <option value="option2">option2</option>
                                        <option value="option3">option3</option>
                                    </select>
                                </td>
                            </tr>
                            <tr >
                                <td>Cell ID</td>
                                <td colSpan={3}>
                                    <select id="type">
                                        <option value="">선택</option>
                                        <option value="option1">option1</option>
                                        <option value="option2">option2</option>
                                        <option value="option3">option3</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button className={`${styles.searchBtn}`}>조회</button>
                    
                </div>
                <div className={`${styles.fileControls}`}>
                    <div className={`${styles.fileUpload}`}>
                        <a href="#">
                            <FontAwesomeIcon icon={faFileArrowUp} />
                            파일 업로드
                        </a>
                    </div>
                    <div className={`${styles.fileDownload}`}>
                        <a href="#">
                            <FontAwesomeIcon icon={faFileArrowDown} />
                            파일 다운로드
                        </a>
                    </div>
                </div>
            </div>
            <div className={`${styles.tableGroup}`}>
                <Table head={head} body={tableData} />
                <div className={`${styles.btnGroup}`}>
                    <div className={`${styles.btnCancle}`}>취소</div>
                    <div className={`${styles.btnConfirm}`}>확인</div>
                </div>
            </div>
        </>
    )

};

export default LocationApp;
