import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import styles from "./modal.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGear,
  faLink,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import Table from "utils/Table";
import { Line } from "react-chartjs-2";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
  registerables,
} from "chart.js";

ChartJS.register(
  ...registerables,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  ArcElement
);

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  headerData?: React.ReactNode;
  children?: React.ReactNode;
  data?: any[];
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  headerData,
  children,
}) => {
  useEffect(() => {
    const handleClose = (e: KeyboardEvent) => {
      if (e.keyCode === 27 || e.keyCode === 13) {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener("keyup", handleClose);
    }

    return () => {
      window.removeEventListener("keyup", handleClose);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className={`${styles.pop}`} onClick={onClose}>
      <div className={`${styles.popBox}`} onClick={(e) => e.stopPropagation()}>
        <div className={`${styles.top}`}>
          {headerData}
          <div className={`${styles.close}`} onClick={onClose}>
            <div className={`${styles.cc} ${styles.c1}`}></div>
            <div className={`${styles.cc} ${styles.c2}`}></div>
          </div>
        </div>
        <div className={`${styles.middle}`}>{children}</div>
        <div className={`${styles.bottom}`}>
          <div className={`${styles.btnBox}`}>
            <div className={`${styles.btn}`} onClick={onClose}>
              확인
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("modal-root") as HTMLElement
  );
};

const InstallModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  headerData,
}) => {
  const head = [
    [
      { title: "번호", col: "no" },
      { title: "설치 ID", col: "installId" },
      { title: "위치 ID", col: "locationId" },
      { title: "자산 ID", col: "assetId" },
      { title: "현황", col: "status" },
      { title: "비고", col: "etc" },
    ],
    [],
  ];

  const [tableData, setTableData] = useState<any>(null);
  // useEffect(() => {

  // },[])

  useEffect(() => {
    const d = new Array(368).fill(null).map((rowData, index) => {
      return {
        no: index,
        installId: "INS-202030901",
        locationId: "POS_001",
        assetId: "ASS-2023-001",
        status: "매핑완료",
        etc: "",
      };
    });
    setTableData(d);

    const handleClose = (e: KeyboardEvent) => {
      if (e.keyCode === 27 || e.keyCode === 13) {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener("keyup", handleClose);
    }

    return () => {
      window.removeEventListener("keyup", handleClose);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className={`${styles.pop}`} onClick={onClose}>
      <div
        className={`${styles.installPopBox}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={`${styles.top}`}>
          {headerData}
          <div className={`${styles.close}`} onClick={onClose}>
            <div className={`${styles.cc} ${styles.c1}`}></div>
            <div className={`${styles.cc} ${styles.c2}`}></div>
          </div>
        </div>

        <div className={`${styles.installHeader}`}>
          <div className={styles.installBox}>
            <div className={styles.installIcon}>
              <FontAwesomeIcon icon={faGear} />
            </div>
            <br /> STEP 1 <br />
            준비단계
            <div className={styles.installContent}>
              자산 : 설치버튼 <br />
              위치 : 설치버튼
            </div>
          </div>
          <div className={styles.arrow}>〉</div>
          <div className={styles.installBox}>
            <div
              className={styles.installIcon}
              style={{ transform: "rotate(45deg)" }}
            >
              <FontAwesomeIcon icon={faLink} />
            </div>
            <br /> STEP 2 <br />
            자산과 위치 연결
            <div className={styles.installContent}>자산 = 위치 매핑</div>
          </div>
          <div className={styles.arrow}>〉</div>
          <div className={styles.installBox}>
            <div className={styles.installIcon}>
              <FontAwesomeIcon icon={faLocationDot} />
            </div>
            <br /> STEP 3 <br />
            현장 설치
            <div className={styles.installContent}>설치 목록 [확인]</div>
          </div>
          <div className={styles.installDescription}>
            <div className={styles.header}>안전지원 장비 설치는......</div>
            <div>
              안전지원 장비 설치는 총 3단계로 이루어지며, 지금 과정은
              <br />
              2단계로 1단계에서 선정한 자산과 위치를 매핑하는 단계입니다.
              <br />
              아래의 좌/우 목록에서 각각 선택하여 매핑하시기 바랍니다.
              <br />
              설치 ID는 매핑 시 자동으로 부여됩니다.
            </div>
          </div>
        </div>
        <div className={`${styles.installFileter}`}>
          <div className={`${styles.filter}`}>
            <div>자산 ID</div>
            <div>
              <select id="type">
                <option value="">선택</option>
                <option value="option1">option1</option>
                <option value="option2">option2</option>
                <option value="option3">option3</option>
              </select>
            </div>
          </div>
          <div
            className={styles.installIcon}
            style={{
              transform: "rotate(45deg)",
              fontSize: "25px",
              fontWeight: "bold",
            }}
          >
            <FontAwesomeIcon icon={faLink} />
          </div>
          <div className={`${styles.filter}`}>
            <div>위치 ID</div>
            <div>
              <select id="type">
                <option value="">선택</option>
                <option value="option1">option1</option>
                <option value="option2">option2</option>
                <option value="option3">option3</option>
              </select>
            </div>
          </div>
        </div>
        <div className={styles.isntallBtn}>매핑</div>
        <div className={`${styles.tableGroup}`}>
          <Table head={head} body={tableData} />
        </div>

        <div className={`${styles.bottom}`}>
          <div className={`${styles.btnBox}`}>
            <div className={`${styles.btnCancle}`} onClick={onClose}>
              취소
            </div>
            <div className={`${styles.btnConfirm}`} onClick={onClose}>
              확인
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("modal-root") as HTMLElement
  );
};

const ObstacleModal: React.FC<ModalProps> = ({ isOpen, onClose, data }) => {
  useEffect(() => {
    const handleClose = (e: KeyboardEvent) => {
      if (e.keyCode === 27 || e.keyCode === 13) {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener("keyup", handleClose);
    }

    return () => {
      window.removeEventListener("keyup", handleClose);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className={`${styles.pop}`} onClick={onClose}>
      <div
        className={`${styles.obstaclePopBox}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={`${styles.top}`}>
          <div className={`${styles.header}`}>
            <p>설치ID</p>
            <p> : {data?.[0]?.id}</p>
          </div>
          <div className={`${styles.close}`} onClick={onClose}>
            <div className={`${styles.cc} ${styles.c1}`}></div>
            <div className={`${styles.cc} ${styles.c2}`}></div>
          </div>
        </div>
        <div className={`${styles.middle}`}>
          <table>
            <tbody>
              <tr>
                <td>위도</td>
                <td>{data?.[0]?.lat}</td>
              </tr>
              <tr>
                <td>경도</td>
                <td>{data?.[0]?.lon}</td>
              </tr>
              <tr>
                <td>기계 명</td>
                <td>{data?.[0]?.markerName}</td>
              </tr>
              <tr>
                <td>상태</td>
                <td>{data?.[0]?.status === 1 ? "정상" : "장애"}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={`${styles.bottom}`}>
          <div className={`${styles.btnBox}`}>
            <div className={`${styles.btnConfirm}`} onClick={onClose}>
              경고 Test
            </div>
            <div className={`${styles.btnCancle}`} onClick={onClose}>
              취소
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("modal-root") as HTMLElement
  );
};

const GraphModal: React.FC<ModalProps> = ({ isOpen, onClose, data }) => {
  const labels_dummy = new Array(data?.[0]?.chartData.length)
    .fill(0)
    .map((_, idx) => idx + 1);
  //chart data
  const mixData = {
    labels: labels_dummy,
    datasets: [
      {
        type: "line" as const,
        label: "유량향후지수" as string,
        data: data?.[0]?.chartData,
        borderColor: data?.[0]?.mdoeColor,
        backgroundColor: data?.[0]?.mdoeColor,
        borderWidth: 1, // 선의 크기
        pointBorderWidth: 2, // point의 크기
        tension: 0.9, // line 차트일 경우 선의 휘어짐 정도
      },
    ],
  };

  useEffect(() => {
    const handleClose = (e: KeyboardEvent) => {
      if (e.keyCode === 27 || e.keyCode === 13) {
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener("keyup", handleClose);
    }

    return () => {
      window.removeEventListener("keyup", handleClose);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className={`${styles.pop}`} onClick={onClose}>
      <div
        className={`${styles.graphPopBox}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={`${styles.top}`}>
          <div className={`${styles.header}`}>
            <p>홍수 : </p>
            <p>{data?.[0]?.disName}</p>
          </div>
          <div className={`${styles.close}`} onClick={onClose}>
            <div className={`${styles.cc} ${styles.c1}`}></div>
            <div className={`${styles.cc} ${styles.c2}`}></div>
          </div>
        </div>
        <div className={`${styles.middle}`}>
          <Line data={mixData} />
        </div>
        <div className={`${styles.bottom}`}>
          <div className={`${styles.btnBox}`}>
            <div className={`${styles.btnConfirm}`} onClick={onClose}>
              확인
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("modal-root") as HTMLElement
  );
};

const TestModal: React.FC<ModalProps> = ({ isOpen, onClose, data }) => {
  // useEffect(() => {
  //     const handleClose = (e: KeyboardEvent) => {
  //         if (e.keyCode === 27 || e.keyCode === 13) {
  //             onClose();
  //         }
  //     };

  //     if (isOpen) {
  //         window.addEventListener("keyup", handleClose);
  //     }

  //     return () => {
  //         window.removeEventListener("keyup", handleClose);
  //     };
  // }, [isOpen, onClose]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className={`${styles.testmodal}`}>
      <div style={{width: "fit-content", height: "fit-content", transform: "scale(4) translate(30%, 30%)"}}>
        <iframe
          src="/display/zdev"
          width="80px"
          height="160px"
          scrolling="no"
        ></iframe>
      </div>
    </div>,
    document.getElementById("modal-root") as HTMLElement
  );
};

export { Modal, InstallModal, ObstacleModal, GraphModal, TestModal };
