// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer_footer__G08an {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    z-index: 100;
    background-color: #b2b2b2;
    display: flex;
    align-items: center;
    color: var(--white);
    text-align: left;
    transform: translateY(-100%);
}
.footer_footer__G08an > p {
    margin: 0;
    padding-left: 20px;
    font-size: 0.9rem;
    font-weight: 100;
}
`, "",{"version":3,"sources":["webpack://./src/pages/99.Footer/footer.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;IAChB,4BAA4B;AAChC;AACA;IACI,SAAS;IACT,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".footer {\n    position: relative;\n    bottom: 0;\n    left: 0;\n    width: 100%;\n    height: 30px;\n    z-index: 100;\n    background-color: #b2b2b2;\n    display: flex;\n    align-items: center;\n    color: var(--white);\n    text-align: left;\n    transform: translateY(-100%);\n}\n.footer > p {\n    margin: 0;\n    padding-left: 20px;\n    font-size: 0.9rem;\n    font-weight: 100;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `footer_footer__G08an`
};
export default ___CSS_LOADER_EXPORT___;
