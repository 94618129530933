import React, { useState, useEffect } from "react";
import styles from "./header.module.scss";
import { Link } from "react-router-dom";

import useAuthStore from "store/zustand";

const TITLE = "안전지원 통합 시스템 v1.0";

const NavItem = (props: any) => {
    return (
        <Link className={`${styles.navItem}`}
        to={props.path}
        >
            <p>{props.children}</p>
        </Link>
    );
};

const Header = () => {
    const userStore = useAuthStore(store => store);
    return (
        <header className={`${styles.header}`}>
            <div>
                <div className={`${styles.headerItem}`}>
                    <div>
                        <Link to="/">{TITLE}</Link>
                    </div>
                </div>
                <div className={`${styles.headerItem}`}>
                    <div className={`${styles.btnGroup}`}>
                        {
                            userStore.isAuthenticated ? 
                            <div>
                                <div style={{cursor : "pointer"}} onClick={() => {
                                    userStore.logout();
                                }}>로그아웃</div>
                            </div> : 
                            <div>
                                <Link to="/login">로그인</Link>
                            </div>
                        }
                        <div>
                            <Link to="/mypage">마이페이지</Link>
                        </div>
                        <div>
                            <Link to="/">사이트맵</Link>
                        </div>
                    </div>
                </div>
            </div>
            <nav className={`${styles.menu}`}>
            {/* <Hamburger /> */}
            <div className={`${styles.menuInner}`}>
                <NavItem path="/dashboard">위험 상황 모니터링</NavItem>
                <NavItem path="/safe-management">안전 정보 관리</NavItem>
                <NavItem path="/system-management">시스템 관리</NavItem>
            </div>
        </nav>
        </header>
    );
};

export default Header;

