import React from "react";

import { Route, Routes, Outlet, BrowserRouter } from "react-router-dom";

import Header from "./0.Header";
import Login from "./10.Login";
import MyPage from "./11.MyPage";
import Main from "./20.Main copy";
import Safe from './22.Safe';
import System from './23.System'
import Footer from "./99.Footer";

import TestApp from './index3';
import TestApp2 from './index4';
import TestApp5 from './index5';


import Display from './80.Display';
import TApp from './81.DisplayTest';

const Layout = () => {
    return <div className={"app-container"}>
        <Header />
        <Outlet />
        <Footer />
    </div>
};

const AppRoute = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/testapp" element={<TApp />} />
                <Route path="/testapp/:id" element={<TApp />} />
                <Route path="/display" element={<Display />} />
                <Route path="/display/:id" element={<Display />} />
                <Route path="/" element={<Layout />}>
                    <Route path="/login" element={<Login breadcrumb={[{ name: "Home" }, { name: "로그인" }]} />} />
                    <Route index element={<Main breadcrumb={[{ name: "Home" }, { name: "대시보드" }]} />} />
                    <Route path="/dashboard" element={<Main breadcrumb={[{ name: "Home" }, { name: "대시보드" }]} />} />
                    <Route path="/flood-management" element={<Main breadcrumb={[{ name: "Home" }, { name: "홍수/침수 정보 관리" }]} />} />
                    <Route path="/mypage" element={<MyPage breadcrumb={[{ name: "Home" }, { name: "마이페이지" }, { name: "정보 관리" }]} />} />
                    <Route path="/safe-management" element={<Safe breadcrumb={[{ name: "Home" }, { name: "안전 정보 관리" }]} />} />
                    <Route path="/system-management/*" element={<System breadcrumb={[{ name: "Home" }, { name: "시스템 관리" }]} />} />
                    <Route path="/test" element={<TestApp />} />
                    <Route path="/test2" element={<TestApp2 />} />
                    <Route path="/test5" element={<TestApp5 />} />
                </Route>
            </Routes>

        </BrowserRouter>
    );
};

export default AppRoute;

