import React, { useState } from "react";
import styles from "./../system.module.scss";

const RemoteApp = (props:any) => {

    return (
        <>
            <div style={{fontWeight:"600"}}>원격 지원 과정</div>
            <div>다음과 같은 절차를 통해서 안전지원 장비를 원격에서 제어 할 수 있습니다.</div>
            <div className={`${styles.remoteGroup}`}>
                <div className={`${styles.remoteBox}`}>
                    <div className={`${styles.boxHeader}`}>
                        <div className={`${styles.boxTriangle}`}>
                            <div className={`${styles.boxNumber}`}>
                                1
                            </div>
                        </div>
                        <div className={`${styles.boxTitle}`}>장비 정보 확인</div>
                    </div>
                    <div className={`${styles.boxContent}`}>
                        대시보드 화면에서 장애 장비 정보 확인 <br/>
                        # 보다 상세한 내용은 관련 매뉴얼 확인 필요
                    </div>
                    <div className={`${styles.boxBtn}`}>장비 정보 확인 바로가기</div>
                </div>
                <div className={`${styles.triangle}`}></div>
                <div className={`${styles.remoteBox}`}>
                    <div className={`${styles.boxHeader}`}>
                        <div className={`${styles.boxTriangle}`}>
                            <div className={`${styles.boxNumber}`}>
                                2
                            </div>
                        </div>
                        <div className={`${styles.boxTitle}`}>원격지원 프로그램 실행</div>
                    </div>
                    <div className={`${styles.boxContent}`}>
                        원격지원 프로그램 실행 <br/>
                        - 예시) 운영자 매뉴얼 기재된 VNC 클라이언트 <br/>
                        1단계 확인한 장비 정보 입력
                    </div>
                </div>
                <div className={`${styles.triangle}`}></div>
                <div className={`${styles.remoteBox}`}>
                    <div className={`${styles.boxHeader}`}>
                        <div className={`${styles.boxTriangle}`}>
                            <div className={`${styles.boxNumber}`}>
                                3
                            </div>
                        </div>
                        <div className={`${styles.boxTitle}`}>장비 정보 확인</div>
                    </div>
                    <div className={`${styles.boxContent}`}>
                        장애 장비 접속 후 필요한 조치 실행
                    </div>
                </div>
            </div>
        </>
    )

};

export default RemoteApp;
