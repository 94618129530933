import React, { useState, useEffect } from "react";
import styles from "./../system.module.scss";
import Table from 'utils/Table';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp, faFileArrowDown } from '@fortawesome/free-solid-svg-icons'

const head = [[
    { title: "번호", col: "no" },
    { title: "사용자명", col: "userNm" },
    { title: "사용자ID", col: "userId" },
    { title: "권한", col: "auth" },
    { title: "소속 부서", col: "department" },
    { title: "휴대전화번호", col: "phoneNum" },
    { title: "E-mail", col: "email" },
    { title: "등록일", col: "createDt" },
    { title: "변경일", col: "udpateDt" },
    { title: "현황", col: "status" },
    { title: "비고", col: "etc" },
],
[

]
]

const UserApp = (props:any) => {

    const [ tableData, setTableData ] = useState<any>(null);

    useEffect(() => {
        const d = new Array(1).fill(null).map((rowData, index) => {
            return { no: index, userNm: "우재원", userId: "honggildong", auth: "서비스 사용자"
                , department: "개발부", phoneNum: "010-7408-3139", email: "jaewon.wu@superstart.co.kr"
                , createDt: "2024-08-28", updateDt: "", status: "사용", etc: "" }
        })
        setTableData(d);
    },[])

    return (
        <>
            <div className={`${styles.filterContainer}`}>
                <div className={`${styles.filterForm}`}>
                    <table className={`${styles.filter}`}>
                        <tbody>
                            <tr>
                                <td>등록일</td>
                                <td>
                                    <input type="date" id={`${styles.startDate}`}/> ~ <input type="date" id={`${styles.endDate}`}/>
                                </td>
                                <td>위험 수준 값</td>
                                <td>
                                    <select id="risk-level">
                                        <option value="">선택</option>
                                        <option value="option1">option1</option>
                                        <option value="option2">option2</option>
                                        <option value="option3">option3</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>유형</td>
                                <td>
                                    <select id="type">
                                        <option value="">선택</option>
                                        <option value="option1">option1</option>
                                        <option value="option2">option2</option>
                                        <option value="option3">option3</option>
                                    </select>
                                </td>
                                <td>메시지 내용</td>
                                <td><input type="text" id="message-content" placeholder="메시지 내용을 입력하세요"/></td>
                            </tr>
                        </tbody>
                    </table>
                    <button className={`${styles.searchBtn}`}>조회</button>
                    
                </div>
                <div className={`${styles.fileControls}`}>
                    <div className={`${styles.fileUpload}`}>
                        <a href="#">
                            <FontAwesomeIcon icon={faFileArrowUp} />
                            파일 업로드
                        </a>
                    </div>
                    <div className={`${styles.fileDownload}`}>
                        <a href="#">
                            <FontAwesomeIcon icon={faFileArrowDown} />
                            파일 다운로드
                        </a>
                    </div>
                </div>
            </div>
            <div className={`${styles.tableGroup}`}>
                <Table head={head} body={tableData} />
                <div className={`${styles.btnGroup}`}>
                    <div className={`${styles.btnCancle}`}>취소</div>
                    <div className={`${styles.btnConfirm}`}>확인</div>
                </div>
            </div>
        </>
    )

};

export default UserApp;
