import React, { useEffect, useRef, useState, createContext, useCallback } from "react";
import styles from "./main.module.scss";

import BreadCrumb from "../2.Breadcrumb";
import { BreadCrumbItem } from "../../types";
import LeftMenu from "./Left";
import Map from './Map';

import axios from "utils/axios";
import { AnySoaRecord } from "dns";
import Timeline from "./Timeline";

// import { FloodPolygonMeta } from "./functions";

import { useDidMountEffect } from 'utils';

declare var kakao: any;

interface InfoData {
    id: number,
    createDt: any,
    warnInfo: any
}

interface FloodDataList {
     no: number
    ,value: any
    ,dangerLevel: any
    ,chartData:any
    ,time: any
    ,sm: any
    ,s1: any
    ,s2: any
    ,s3: any
    ,s4: any
    ,s5: any
}

export const MainContext = createContext({
    // floodData: [],
    // setFloodDataHandler: (data: any) => { },
    infoData: {
        id: undefined, createDt: undefined, warnInfo: { flood: 0, flood_p: 0, marker: 0, marker_p: 0 }
    },
    setInfoDataHandler: (data: any) => { },
    setRefresh: (data: any) => { },
    FloodDataList:[{workClass:undefined, no: undefined ,value: undefined ,dangerLevel: undefined ,time: undefined ,sm: undefined , chartData:[] ,s1: ' ' ,s2: ' ' ,s3: ' ' ,s4: ' ',s5: ' ' }],
    GetFloodData:{ no: undefined ,value: undefined ,dangerLevel: undefined ,time: undefined ,sm: undefined ,s1: ' ' ,s2: ' ' ,s3: ' ' ,s4: ' ',s5: ' ' },
    GetFloodDataHandler: (data: any) => { },
    GetWorkClassHandler : (data: any) => { },
    workClass: ''
});

const Main = (props: any) => {
    const [infoData, setInfoData] = useState<any>(null);
    const [refresh, setRefresh] = useState<any>(null);
    const [FloodDataList, setFloodDataList] = useState<any>(null);
    const [GetFloodData, setGetFloodData] = useState<any>(null);
    const [workClass, setWorkClass] = useState<any>(null);

    const setInfoDataHandler = (data: any) => setInfoData(data);
    const GetFloodDataHandler= (data: any) => setGetFloodData(data.row);
    const GetWorkClassHandler= (data: any) => setWorkClass(data.workClass);


    const getFloodData = async (id?: string) => {
        const response = await axios.post('/main/getInfoData', { id: infoData?.id });
        const row = response.data?.data;
        setInfoData({ id: row.id, createDt: row.createDt, warnInfo: row.infoData })

        const request1 = await axios.post("/map/getPolygonDB");
        const data1:any = request1?.data?.data;


        const dataLis:any = data1.map((row: any, index: number) => ({
            ...row
            , sNo : index +1
            , name: '제주시'
            , trends : row.sm
            , workClass : '1'
        }))

        const request2 = await axios.post("/map/getPolygon2DB");
        const data2:any = request2?.data?.data;

        const dataLis2:any = data2.map((row: any, index: number) => ({
            ...row
            , sNo : index +1
            , name: '제주시'
            , trends : row.sm
            , workClass : '2'
        }))

        setFloodDataList([...dataLis, ...dataLis2]);
        setWorkClass('1')
    }

    useEffect(() => {
        getFloodData()
    }, [])

    return (
        <MainContext.Provider value={{ setInfoDataHandler, GetFloodDataHandler, infoData, FloodDataList, GetFloodData, workClass, GetWorkClassHandler, setRefresh }}>
            <main>
                <BreadCrumb breadcrumb={props.breadcrumb} />
                <div className={`${styles.MainContainer}`}>
                    <LeftMenu  />

                    <div className={`${styles.Main}`}>
                        <Map infoData={infoData} refresh={new Date().toString()} kakao={kakao} />
                    </div>
                    {/* {
                        infoData?.id && <Timeline />
                    } */}
                </div>
            </main>
        </MainContext.Provider>
    );
};

export default Main;

