import React, { useState, useEffect } from "react";
import styles from "./../system.module.scss";
import Table from 'utils/Table';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp, faFileArrowDown } from '@fortawesome/free-solid-svg-icons'

const head = [[
    { title: "번호", col: "no" },
    { title: "자산ID", col: "assetId" },
    { title: "MAC주소", col: "macAddress" },
    { title: "Serial NO.", col: "serialNo" },
    { title: "등록일", col: "createDt" },
    { title: "변경일", col: "udpateDt" },
    { title: "현황", col: "status" },
    { title: "설치신고", col: "install" },
    { title: "비고", col: "etc" },
],
[

]
]

const AssetApp = (props:any) => {

    const [ tableData, setTableData ] = useState<any>(null);

    useEffect(() => {
        const d = new Array(368).fill(null).map((rowData, index) => {
            return { no: index, assetId: "ASS-2023-001", macAddress: "38-FC-98-6F-DD-21"
                , serialNo: "ALL" , createDt: "2023-12-01"
                , updateDt: "", status: "등록",install: "O",  etc: "" }
        })
        setTableData(d);
    },[])

    return (
        <>
            <div className={`${styles.filterContainer}`}>
                <div className={`${styles.filterForm}`}>
                    <table className={`${styles.filter}`}>
                        <tbody>
                            <tr>
                                <td>등록일</td>
                                <td>
                                    <input type="date" id={`${styles.startDate}`}/> ~ <input type="date" id={`${styles.endDate}`}/>
                                </td>
                                <td>현황</td>
                                <td>
                                    <select id="risk-level"  style={{width:"100%"}}>
                                        <option value="">선택</option>
                                        <option value="option1">option1</option>
                                        <option value="option2">option2</option>
                                        <option value="option3">option3</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button className={`${styles.searchBtn}`}>조회</button>
                    
                </div>
                <div className={`${styles.fileControls}`}>
                    <div className={`${styles.fileUpload}`}>
                        <a href="#">
                            <FontAwesomeIcon icon={faFileArrowUp} />
                            파일 업로드
                        </a>
                    </div>
                    <div className={`${styles.fileDownload}`}>
                        <a href="#">
                            <FontAwesomeIcon icon={faFileArrowDown} />
                            파일 다운로드
                        </a>
                    </div>
                </div>
            </div>
            <div className={`${styles.tableGroup}`}>
                <Table head={head} body={tableData} />
                <div className={`${styles.btnGroup}`}>
                    <div className={`${styles.btnCancle}`}>취소</div>
                    <div className={`${styles.btnConfirm}`}>확인</div>
                </div>
            </div>
        </>
    )

};

export default AssetApp;
