import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import styles from "./main.module.scss";
import { Pagination } from 'utils/Table/functions';

import { MainContext } from ".";

import axios from 'utils/axios';

const dangerMenu = [
    { label: "전체", value: undefined },
    { label: "1단계", value: '1' },
    { label: "2단계", value: '2' },
    { label: "3단계", value: '3' }
]

const CONDITION = {workClass:undefined, area: undefined, danger: undefined, trend: undefined};

const LeftMenu = (props: any) => {
    const [showLeft, setShowLeft] = useState<boolean>(true)
    const [areaNames, setAreaNames] = useState<any[]>([]);
    const [workClassNames, setWorkClassNames] = useState<any[]>([]);
    const [ condition, setCondition ] = useState<any>(CONDITION)
    const { infoData } = useContext(MainContext);
    const { FloodDataList } = useContext(MainContext);
    const { GetFloodData } = useContext(MainContext);
    const { GetFloodDataHandler } = useContext(MainContext);
    const { GetWorkClassHandler } = useContext(MainContext);

    const [ floodData, setFloodData ] = useState<any[]>([]);

    const TBL_DATA = useRef<any[]>([]);
    const [viewCnt, setViewCnt] = useState<number>(10);
    const PAGER = useRef<Pagination>(new Pagination());


    const initialMenu = async () => {
        // const response = await axios.get("/main/getAreas")
        // const data = response.data?.data || [];
        setAreaNames([{ label: '전체', value: undefined },{label: '제주시', value: '제주시'}]);
        setWorkClassNames([{ label: '침수', value: '1' },{label: '홍수', value: '2'}]);
    }

    const getFloodData = async () => {

        if(FloodDataList != null) {

            let rowData = [...FloodDataList];
            if(condition.workClass || condition.area || condition.danger || condition.trend) {
                rowData = rowData.filter((data:any) => {
                    if(condition.workClass && data.workClass !== condition.workClass) {
                        return false;
                    }

                    if(condition.area && data.name !== condition.area) {
                        return false;
                    }
                    if(condition.danger && data.dangerLevel !== condition.danger) {
                        return false;
                    }
                    if(condition.trend !== undefined && data.trends != condition.trend) {
                        return false;
                    }
                    return true;
                })
            }

            setFloodData(rowData);
            GetWorkClassHandler({workClass:condition.workClass});

            TBL_DATA.current = rowData;
            PAGER.current = new Pagination(TBL_DATA.current.length, viewCnt)
            setPager();

        }
    }

    const setPager = () => {
        const range = PAGER.current.getItemRange();
        let setData: any[] = [];
        for (let i = range.start; i < range.end; i++) {
            setData.push(TBL_DATA.current[i]);
        }
        setFloodData(setData);
    }


    const SetClickData = (parmData:any) =>{
        GetFloodDataHandler(parmData)
    }

    useEffect(() => {
        initialMenu();
    }, [])

    useEffect(() => {
        getFloodData();
    },[FloodDataList, condition])

    const renderTableData = useMemo(() => {
        
        let rowData = [...floodData];
       
        if (rowData.length > 0) {
            return rowData.map((row: any, index: number) => {
                let color;
                if(row.dangerLevel === "1"){
                    color = "rgb(255, 255, 0, 0.5)"
                } else if (row.dangerLevel === "2"){
                    color = "rgb(255, 127, 0, 0.5)"
                } else {
                    color = "rgb(255, 0, 0, 0.5)"
                }
                return <tr key={`indexLEFT${index}`} 
                           onClick={() => {SetClickData({row})}}
                           style={{backgroundColor : color}}>
                    <td>{row.sNo}</td>
                    <td>{row.name}</td>
                    <td>{row.dangerLevel}</td>
                    <td>{row.sm === '2' ? "▲" : (row.sm === '0'? "-": "▼")}</td>
                </tr>
            })
        } else {
            return <tr>
                <td colSpan={4}>데이터가 없습니다.</td>
            </tr>
        }
    }, [floodData, GetFloodData])

    const HandleConditionChange = (e:any) => {

        if(e) {
            setCondition((con:any) => ({...con, [e.target.name]: e.target.value === "전체" ? undefined : e.target.value}));
        } else {
            setCondition(CONDITION)
        }
            
    }

    
    const workClassMenuRender = useMemo(() => {
        return workClassNames.map((row, index) => {
            return <option value={row.value} key={"workClass"+row.value?.toString()}>{row.label}</option>
        })
    }, [workClassNames])


    const areaMenuRender = useMemo(() => {
        return areaNames.map((row, index) => {
            return <option value={row.value} key={"area"+row.value?.toString()}>{row.label}</option>
        })
    }, [areaNames])

    const dangerMenuRender = useMemo(() => {
        return dangerMenu.map((row, index) => {
            return <option value={row.value} key={"danger"+row.value?.toString()}>{row.label}</option>
        })
    }, [])

    const ChangePage = (data:any) => {
        PAGER.current.setPage(data.page);
        setPager()
    }

    const FOOTER_RENDER = () => {
        if (floodData.length) {
            const range = PAGER.current.getPageRange();
            let renderData = [];

            if (range.prevPage > 0) {
                renderData.push(
                    <span
                        key={"pagination_prev"}
                        aria-hidden={true}
                        onClick={() => {ChangePage({page: range.prevPage})}}
                    >
                        {"<"}
                    </span>
                );
            }
            for (let i = range.blockBegin; i <= range.blockEnd; i++) {
                renderData.push(
                    <span
                        key={"pagination" + i}
                        className={i === range.page ? styles.pageON : undefined}
                        onClick={() => {ChangePage({page: i})}}
                        aria-hidden={true}
                    >
                        {i}
                    </span>
                );
            }
            if (range.nextPage > 0) {
                renderData.push(
                    <span
                        key={"pagination_next"}
                        aria-hidden={true}
                        onClick={() => {ChangePage({page: range.nextPage})}}
                    >
                        {">"}
                    </span>
                );
            }


            return <tr>
                <td colSpan={4}>
                    <span className={`${styles.paginationUL}`}>
                        {renderData.map(e => {
                            return e
                        })}
                    </span>
                </td>
            </tr>
        }
    }

    const footer = useMemo(FOOTER_RENDER, undefined)

    return (
        <div className={`${styles.MainLeft} ${showLeft ? styles.show : null}`}>
            {/* <div className={`${styles.MainLeftContainer}`}> */}
                {/* <button className={`${styles.MainLeftCloseBtn}`}
                    onClick={() => { setShowLeft(old => !old) }}
                >메뉴</button> */}
                <ul>
                    <li>
                        구분
                        <select value={condition.workClass} onChange={HandleConditionChange} name={"workClass"}>
                            {workClassMenuRender}
                        </select>
                    </li>
                    <li>
                        행정구역명
                        <select value={condition.area} onChange={HandleConditionChange} name={"area"}>
                            {areaMenuRender}
                        </select>
                    </li>
                    <li>
                        위험수준값
                        <select value={condition.danger} onChange={HandleConditionChange} name={"danger"}>
                            {dangerMenuRender}
                        </select>
                    </li>
                    <li>
                        위험수준<br/>추세
                        <label className={`${styles.RadioBox}`}>
                            <span>이전 시간 대비 증가</span>
                            <input type="radio" name="trend" value={'2'} onChange={HandleConditionChange} checked={condition.trend === '2'} />
                        </label>
                        <label className={`${styles.RadioBox}`}>
                            <span>이전 시간 대비 감소</span>
                            <input type="radio" name="trend" value={'1'} onChange={HandleConditionChange} checked={condition.trend === '1'} />
                        </label>
                    </li>
                </ul>
                {/* <table className={`${styles.MainLeftTable}`}>
                    <colgroup>
                        <col width="40%" />
                        <col />
                    </colgroup>
                    <tbody>
                        <tr>
                            <td>행정구역명</td>
                            <td>
                                <select value={condition.area} onChange={HandleConditionChange} name={"area"}>
                                    {areaMenuRender}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>위험수준값</td>
                            <td>
                                <select value={condition.danger} onChange={HandleConditionChange} name={"danger"}>
                                    {dangerMenuRender}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>위험수준 추세</td>
                            <td>
                                <label className={`${styles.RadioBox}`}>
                                    <input type="radio" name="trend" value={1} onChange={HandleConditionChange} checked={condition.trend === '1'} />
                                    <span>이전 시간 대비 증가</span>
                                </label>
                                <label className={`${styles.RadioBox}`}>
                                    <input type="radio" name="trend" value={0} onChange={HandleConditionChange} checked={condition.trend === '0'} />
                                    <span>이전 시간 대비 감소</span>
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table> */}
                <div className={`${styles.MainLeftTableBtn}`}>
                    <button onClick={() => HandleConditionChange(null)}>초기화</button>
                </div>
                <div className={`${styles.MainLeftBottomTable}`}>
                    <table>
                        <colgroup>
                            <col width="30px" />
                            <col />
                            <col width="40px" />
                            <col width="40px" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>행정<br/>구역명</th>
                                <th>위험<br/>수준</th>
                                <th>추세</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderTableData}
                        </tbody>
                        <tfoot>
                            {footer}
                        </tfoot>
                    </table>
                </div>
            {/* </div> */}
        </div>
    );
};

export default LeftMenu;

