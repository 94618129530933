import React, { useState, useEffect } from "react";
import styles from "./../system.module.scss";
import Table from 'utils/Table';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp, faFileArrowDown } from '@fortawesome/free-solid-svg-icons'

const head = [[
    { title: "번호", col: "no" },
    { title: "설치 ID", col: "installId" },
    { title: "위치 ID", col: "locationId" },
    { title: "행정구역명", col: "districtNm" },
    { title: "행정구역코드", col: "districtCd" },
    { title: "Cell ID", col: "cellId" },
    { title: "자산 ID", col: "assetId" },
    { title: "IP 주소", col: "ipAddress" },
    { title: "MAC 주소", col: "macAddress" },
    { title: "설치 정보", col: "installInfo" },
    { title: "설치 예정일", col: "installDt" },
    { title: "변경일", col: "updateDt" },
    { title: "현황", col: "status" },
    { title: "비고", col: "etc" },
],
[

]
]


const InstallationApp = (props:any) => {

    const [ tableData, setTableData ] = useState<any>(null);

    useEffect(() => {
        const d = new Array(4).fill(null).map((rowData, index) => {
		index = index+1
            return { no: index, installId: "INS-202030901-0"+index, locationId: "POS_00"+index
                , districtNm: "제주시" , districtCd: "39-010-11"+index, cellId:"-"
                , assetId: "ASS-2023-00"+index,ipAddress :"192.168.123.20"+index, macAddress:"38-FC-98-6F-DD-2"+index
                ,installInfo: "-"
                ,installDt:"2024.04.22", updateDt: "2024.04.22", status: "준비", etc: "" }
        })
        setTableData(d);
    },[])

    return (
        <>
            <div className={`${styles.filterContainer}`}>
                <div className={`${styles.filterForm}`}>
                    <table className={`${styles.filter}`}>
                        <tbody>
                            <tr>
                                <td>설치 예정일</td>
                                <td>
                                    <input type="date" id={`${styles.startDate}`}/> ~ <input type="date" id={`${styles.endDate}`}/>
                                </td>
                                <td>행정구역 명</td>
                                <td>
                                    <select id="risk-level" style={{width:"100%"}}>
                                        <option value="">선택</option>
                                        <option value="option1">option1</option>
                                        <option value="option2">option2</option>
                                        <option value="option3">option3</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>현황</td>
                                <td colSpan={3}>
                                    <select id="type">
                                        <option value="">선택</option>
                                        <option value="option1">option1</option>
                                        <option value="option2">option2</option>
                                        <option value="option3">option3</option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button className={`${styles.searchBtn}`}>조회</button>
                    
                </div>
                <div className={`${styles.fileControls}`}>
                    <div className={`${styles.fileUpload}`}>
                        <a href="#">
                            <FontAwesomeIcon icon={faFileArrowUp} />
                            파일 업로드
                        </a>
                    </div>
                    <div className={`${styles.fileDownload}`}>
                        <a href="#">
                            <FontAwesomeIcon icon={faFileArrowDown} />
                            파일 다운로드
                        </a>
                    </div>
                </div>
            </div>
            <div className={`${styles.tableGroup}`}>
                <Table head={head} body={tableData} />
                <div className={`${styles.btnGroup}`}>
                    <div className={`${styles.btnCancle}`}>취소</div>
                    <div className={`${styles.btnConfirm}`}>확인</div>
                </div>
            </div>
        </>
    )

};

export default InstallationApp;
