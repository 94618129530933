import React, { useState } from "react";
import styles from "./login.module.scss";
import BreadCrumb from "../2.Breadcrumb";
import { useNavigate } from "react-router-dom";

import useAuthStore from "store/zustand";

const LoginApp = (props:any) => {

    const loginFunction = useAuthStore((state) => state.login)

    const [ id, setId ] = useState<string>("");
    const [ pw, setPw ] = useState<string>("");

    const navigate = useNavigate();
    
    const login = () => {
        if(!id || !pw) {
            return;
        }

        const idval = 'admin';
        const pwval = "1234";

        if (id === idval && pw === pwval){
            loginFunction({id: id});
            navigate("/", {replace: true});
        } else {
            alert("아이디나 비밀번호가 일치하지 않습니다.");
        }
    }

    return (
        <main>
            <BreadCrumb breadcrumb={props.breadcrumb} />
            <div className={`common-title-box`}>
                <div>
                    <h2>로그인</h2>
                    <p>안전지원 통합 시스템에 오신 것을 환영합니다.</p>
                </div>
            </div>
            <div className={`${styles.LoginContainer}`}>
                <table>
                    <colgroup>
                        <col width="300px"></col>
                        <col width="100px"></col>
                    </colgroup>
                    <tbody>
                        <tr>
                            <td>
                                <input type="text" name={"id"} placeholder="아이디를 입력하세요." value={id} onChange={(e) => {setId(e.target.value)}} />
                            </td>
                            <td rowSpan={2}>
                                <button onClick={login}>로그인</button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="password" name={"password"} placeholder="비밀번호를 입력하세요." value={pw} onChange={(e) => {setPw(e.target.value)}} />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <label>
                                    <input type="checkbox"></input>아이디 저장
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </main>
    );
};

export default LoginApp;


