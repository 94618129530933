import React, { useState } from "react";
import styles from "./system.module.scss";
import { Link, useLocation } from "react-router-dom";
import { InstallModal } from "utils/Modal/modal";


const SystemLeftMenu = () => {
    const path = window.location.pathname.replace("/system-management/", ""); 
    const location = useLocation();
    const [ModalOpen, SetModalOpen] = useState(false)
    const [headerData, SetHeaderData] = useState<React.ReactNode>(null);
    
    const openModal = () => {
        SetHeaderData(<div style={{display:"flex", gap:"10px", alignItems:"center"}}>
            <p style={{fontWeight:"bold"}}>설치준비</p><p style={{fontSize:"14px"}}> : 자산 = 위치 매핑</p>
        </div>);
        SetModalOpen(true);
    };
    
    const closeModal = () => {
        SetModalOpen(false);
    };

    return (
        <>
        <div className={`${styles.sideMenu}`}>
            <ul>
                <Link to={"/system-management"} 
                        style={{ textDecoration: "none", color: "black"}}>
                    <li className={`${ path==="/system-management" ? styles.active : ""}`}>
                        사용자 관리
                    </li>
                </Link>
                <Link to={"/system-management/authority"} 
                        style={{ textDecoration: "none", color: "black"}}>
                    <li className={`${ path==="authority" ? styles.active : ""}`}>
                        권한 관리
                    </li>
                </Link>
                <Link to={"/system-management/code"} 
                        style={{ textDecoration: "none", color: "black"}}>
                    <li className={`${ path==="code" ? styles.active : ""}`}>
                        코드 관리
                    </li>
                </Link>
                <Link to={"/system-management/location"} 
                        style={{ textDecoration: "none", color: "black"}}>
                    <li className={`${ path==="location" ? styles.active : ""}`}>
                        위치 관리
                    </li>
                </Link>
                <Link to={"/system-management/asset"} 
                        style={{ textDecoration: "none", color: "black"}}>
                    <li className={`${ path==="asset" ? styles.active : ""}`}>
                        자산 관리
                    </li>
                </Link>
                <Link to={"/system-management/install"} 
                        style={{ textDecoration: "none", color: "black"}}>
                    <li className={`${ path==="install" ? styles.active : ""}`}>
                        설치 관리
                    </li>
                </Link>
                    <li className={`${ path==="install" ? styles.depthActive : ""}`} 
                        style={path==="install" ? {display:"block"} : {display: "none"}}
                        onClick={openModal}>
                        〉 설치 준비
                    </li>
                    <li className={`${ path==="install" ? styles.depthActive : ""}`} 
                        style={path==="install" ? {display:"block"} : {display: "none"}}>
                        〉 설치 확인
                    </li>
                <Link to={"/system-management/obstacle"} 
                        style={{ textDecoration: "none", color: "black"}}>
                    <li className={`${ path==="obstacle" ? styles.active : ""}`}>
                        장애 관리
                    </li>
                </Link>
                <Link to={"/system-management/log"} 
                        style={{ textDecoration: "none", color: "black"}}>
                    <li className={`${ path==="log" ? styles.active : ""}`}>
                        로그 관리
                    </li>
                </Link>
                <Link to={"/system-management/remote"} 
                        style={{ textDecoration: "none", color: "black"}}>
                    <li className={`${ path==="remote" ? styles.active : ""}`}>
                        장비 원격지원
                    </li>
                </Link>
            </ul>
        </div>
        <InstallModal isOpen={ModalOpen} onClose={closeModal} headerData={headerData}/>
        </>        
    );
};

export default SystemLeftMenu;