import React, { useState, useMemo, useEffect } from "react";
import styles from "./safe.module.scss";
import BreadCrumb from "../2.Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp, faFileArrowDown } from '@fortawesome/free-solid-svg-icons'
import Table from 'utils/Table';


const head = [[
    { title: "번호", col: "no" },
    { title: "안전 정보 ID", col: "safeId" },
    { title: "유형", col: "type" },
    { title: "위험 수준 값", col: "dangerVal" },
    { title: "문자 안전 정보", col: "textWarn" },
    { title: "음성 안전 정보", col: "soundWarn" },
    { title: "등록일", col: "createDt" },
    { title: "변경일", col: "udpateDt" },
    { title: "현황", col: "status" },
    { title: "비고", col: "etc" },
],
[

]
]
const body: any = [
    { no: 1, safeId: "MSG-001", type: "ALL", dangerVal: 1, textWarn: "홍수 지역 입니다. 신속히 대피하세요.", soundWarn: "홍수 지역입니다. 신속히 대피하세요.", createDt: "2023-12-01", updateDt: "", status: "사용", etc: "" },
    { no: 2, safeId: "MSG-002", type: "TXT", dangerVal: 1, textWarn: "홍수 지역 입니다. 신속히 대피하세요.", soundWarn: "홍수 지역입니다. 신속히 대피하세요.", createDt: "2023-12-01", updateDt: "", status: "사용", etc: "" },
    { no: 3, safeId: "MSG-003", type: "VCE", dangerVal: 1, textWarn: "홍수 지역 입니다. 신속히 대피하세요.", soundWarn: "홍수 지역입니다. 신속히 대피하세요.", createDt: "2023-12-01", updateDt: "", status: "사용", etc: "" },
    { no: 4, safeId: "MSG-004", type: "ALL", dangerVal: 1, textWarn: "홍수 지역 입니다. 신속히 대피하세요.", soundWarn: "홍수 지역입니다. 신속히 대피하세요.", createDt: "2023-12-01", updateDt: "", status: "사용", etc: "" },
    { no: 5, safeId: "MSG-005", type: "ALL", dangerVal: 1, textWarn: "홍수 지역 입니다. 신속히 대피하세요.", soundWarn: "홍수 지역입니다. 신속히 대피하세요.", createDt: "2023-12-01", updateDt: "", status: "사용", etc: "" },
    { no: 6, safeId: "MSG-006", type: "ALL", dangerVal: 1, textWarn: "홍수 지역 입니다. 신속히 대피하세요.", soundWarn: "홍수 지역입니다. 신속히 대피하세요.", createDt: "2023-12-01", updateDt: "", status: "사용", etc: "" },
]


const SafeManagement = (props:any) => {

    const [ tableData, setTableData ] = useState<any>(null);

    useEffect(() => {
        const d = new Array(368).fill(null).map((rowData, index) => {
            return { no: index, safeId: "MSG-"+index, type: "ALL", dangerVal: 1, textWarn: "홍수 지역 입니다. 신속히 대피하세요.", soundWarn: "홍수 지역입니다. 신속히 대피하세요.", createDt: "2023-12-01", updateDt: "", status: "사용", etc: "" }
        })
	const dd = [
	{no: 1, safeId: "MSG-1", type: "Type-1", dangerVal: 1, textWarn: "집중호우 / 주의요망", soundWarn: "", createDt: "2024-10-10", updateDt: "", status: "사용", etc: ""},
	{no: 2, safeId: "MSG-2", type: "Type-2", dangerVal: 1, textWarn: "중산간 / 폭우발생 / 홍수위험", soundWarn: "", createDt: "2024-10-10", updateDt: "", status: "사용", etc: ""},
	{no: 3, safeId: "MSG-3", type: "Type-3", dangerVal: 1, textWarn: "안전구역 / 신속한 / 대피바람", soundWarn: "", createDt: "2024-10-10", updateDt: "", status: "사용", etc: ""},
	{no: 4, safeId: "MSG-4", type: "Type-4", dangerVal: 1, textWarn: "홍수발생 / 통행금지 / 즉시대피", soundWarn: "", createDt: "2024-10-10", updateDt: "", status: "사용", etc: ""},
	]
        setTableData(dd);
    },[])

    return (
        <>
        <main>
            <BreadCrumb breadcrumb={props.breadcrumb} />
            <div className={`common-title-box`}>
                <div>
                    <h2>안전 정보 관리</h2>
                    <p>각종 안전 정보를 관리 할 수 있습니다.</p>
                </div>
            </div>
            <div className={`${styles.filterContainer}`}>
                <div className={`${styles.filterForm}`}>
                    <table className={`${styles.filter}`}>
                        <tbody>
                            <tr>
                                <td>등록일</td>
                                <td>
                                    <input type="date" id={`${styles.startDate}`}/> ~ <input type="date" id={`${styles.endDate}`}/>
                                </td>
                                <td>위험 수준 값</td>
                                <td>
                                    <select id="risk-level">
                                        <option value="">선택</option>
                                        <option value="option1">option1</option>
                                        <option value="option2">option2</option>
                                        <option value="option3">option3</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>유형</td>
                                <td>
                                    <select id="type">
                                        <option value="">선택</option>
                                        <option value="option1">option1</option>
                                        <option value="option2">option2</option>
                                        <option value="option3">option3</option>
                                    </select>
                                </td>
                                <td>메시지 내용</td>
                                <td><input type="text" id="message-content" placeholder="메시지 내용을 입력하세요"/></td>
                            </tr>
                        </tbody>
                    </table>
                    <button className={`${styles.searchBtn}`}>조회</button>
                    
                </div>
                <div className={`${styles.fileControls}`}>
                    <div className={`${styles.fileUpload}`}>
                        <a href="#">
                            <FontAwesomeIcon icon={faFileArrowUp} />
                            파일 업로드
                        </a>
                    </div>
                    <div className={`${styles.fileDownload}`}>
                        <a href="#">
                            <FontAwesomeIcon icon={faFileArrowDown} />
                            파일 다운로드
                        </a>
                    </div>
                </div>
            </div>
            <div>
                <Table head={head} body={tableData} />
            </div>
        </main>
        </>
    );
};
export default SafeManagement;
